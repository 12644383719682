<template>
    <div class="content">
        <div>
            <el-button type="primary" class="el-icon-plus" @click="Add"> 新增</el-button>
        </div>

         <el-card style="margin-top:15px;width:100%" class="box-card">
            <div class="search">
                <div class="item">
                    <label>姓名</label>
                    <el-input v-model="searchForm.car_name" class="marg" style="width:150px"></el-input>
                </div>
                <div class="item">
                    <label>车牌号</label>
                    <el-input v-model="searchForm.car_no" class="marg" style="width:150px"></el-input>
                </div>
                <div class="item">
                    <label>公司</label>
                    <el-select v-model="searchForm.cp_id" style="width:150px">
                        <el-option label="全部" :value="0"></el-option>
                        <el-option v-for='item in $store.state.CompanyDataList' :key='item.cp_id' :label='item.cp_name' :value='item.cp_id'></el-option>
                    </el-select>
                </div>
                <el-button type="primary" plain @click="Search" style="margin-left: 20px;">查询</el-button>
            </div>
        </el-card>

        <el-table :data='$store.state.CarDataList' border style="width: 100%;font-size:13px" stripe :highlight-current-row='true' size="mini">
            <el-table-column align="center" prop="car_name" label="姓名"></el-table-column>
            <el-table-column align="center" prop="car_no" label="车牌号"></el-table-column>
            <el-table-column align="center" prop="cp_name" label="所属公司"></el-table-column>
            <el-table-column align="center" prop="car_phone" label="联系方式"></el-table-column>
            <el-table-column align="center" prop="car_limit_height" label="限高(米)"></el-table-column>
            <el-table-column align="center" prop="car_limit_weight" label="限重(吨)"></el-table-column>
            <el-table-column align="center" prop="car_load" label="载重(米)"></el-table-column>
            <el-table-column align="center" prop="car_overhaul" label="年检情况"></el-table-column>
            <el-table-column align="center" prop="car_state" label="车辆状态">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.car_state === 0 ? 'success' : 'danger'" disable-transitions> {{scope.row.car_state===0?'正常':'黑名单'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-link type="primary" @click="Edit(scope.row)">编辑</el-link>
                    <el-link type="danger" @click="Del(scope.row)">删除</el-link>
                    <el-dropdown  @command="BlackListOpt($event,scope.row)">
                        <span style="font-size:12px;color:#409EFF">名单<i class="el-icon-arrow-down el-icon--right"></i></span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item style="color:red" command="1">设置黑名单</el-dropdown-item>
                          <el-dropdown-item style="color:#409EFF" command="0"> 解除黑名单</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="width:100%" layout="prev, pager, next" @current-change="current_change" :total="$store.state.CarDataTotal" background :page-size="searchForm.pageSize"></el-pagination>



        <el-dialog v-if="dialogVisible" :title="title" :visible.sync="dialogVisible" width='40%' :append-to-body='true'>
            <el-form :model="carForm" :rules="rules" ref="carForm" label-width="100px" size='medium'>
                <el-form-item label="姓名" prop="car_name">
                     <el-input v-model="carForm.car_name"></el-input>
                </el-form-item>
                <el-form-item label="车牌号" prop="car_no">
                    <el-input v-model="carForm.car_no"></el-input>
                </el-form-item>
                <el-form-item label="所属公司">
                    <el-select v-model="carForm.car_company_id" style="width:100%;">
                        <el-option v-for='item in $store.state.CompanyDataList' :key='item.cp_id' :label='item.cp_name' :value='item.cp_id'></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系方式">
                    <el-input v-model="carForm.car_phone"></el-input>
                </el-form-item>
                <el-form-item label="身份证号">
                    <el-input v-model="carForm.car_number"></el-input>
                </el-form-item>
                <el-form-item label="限高(米)">
                    <el-input-number v-model="carForm.car_limit_height" :precision="2" :step="0.1" :max="10" style="width: 100%;"></el-input-number>
                </el-form-item>
                <el-form-item label="限重(吨)">
                    <el-input-number v-model="carForm.car_limit_weight" :precision="2" :step="0.1" :max="10" style="width: 100%;"></el-input-number>
                </el-form-item>
                <el-form-item label="载重(吨)">
                    <el-input-number v-model="carForm.car_load" :precision="2" :step="0.1" :max="10" style="width: 100%;"></el-input-number>
                </el-form-item>
                <el-form-item label="年检情况">
                    <el-input v-model="carForm.car_overhaul"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import {get,post} from '../../util/util'


export default{
    data(){
        return{
            searchForm:{
                pageNo:1,
                pageSize:15,
                car_name:'',
                car_no:'',
                cp_id:0
            },

            dialogVisible:false,
            title:'',
            carForm:{
                car_id:0,
                car_name:'',
                car_no:'',
                car_phone:'',
                car_limit_height:0,
                car_limit_weight:0,
                car_load:0,
                car_overhaul:'',
                car_number:'',
                car_company_id:0
            },

            rules:{
                car_name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                car_no: [{ required: true, message: '请输入车牌号', trigger: 'blur' }],
            }
        }
    },
    created(){
        if(this.$store.state.CompanyDataList.length===0){
            this.$store.dispatch('getCompanyDataList')
        }
    },
    mounted(){
        if(this.$store.state.CarDataList.length===0){
            this.$store.dispatch('getCarDataList',this.searchForm)
        }
    },
    methods:{
        Search(){
            this.$store.dispatch('getCarDataList',this.searchForm)
        },
        Add(){
           this.title='新增'
           this.dialogVisible=true
           this.carForm.car_id=0
           this.carForm.car_name=''
           this.carForm.car_no=''
           this.carForm.car_phone=''
           this.carForm.car_limit_height=0
           this.carForm.car_limit_weight=0
           this.carForm.car_load=0
           this.carForm.car_overhaul=''
           this.carForm.car_number=''
           this.carForm.car_company_id=0
           if(this.$store.state.CompanyDataList.length>0){
            this.carForm.car_company_id=this.$store.state.CompanyDataList[0].cp_id
           }
        },
        current_change(page){
            this.searchForm.pageNo=page
            this.Search()
        },
        Edit(row){
            this.dialogVisible=true
            this.title='修改'
            this.carForm=JSON.parse(JSON.stringify(row))
        },
        Del(row){
            this.$confirm('确定删除车辆“' + row.car_no + '”？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                get('emp/del?id=' + row.car_id+'&name='+row.car_no).then(res => {
                    if (res.rpStatus === 10000) {
                        this.searchForm={
                            pageNo:1,
                            pageSize:15,
                            car_name:'',
                            car_no:'',
                            cp_id:0
                        }
                        this.$store.dispatch('getCarDataList',this.searchForm)
                    } else { this.$message.error(res.rpMsg) }
                })
            }).catch(e => e)
        },
        submit(){
            this.$refs.carForm.validate((valid) => {
                if (valid) {
                    post('car/edit', this.carForm).then(res => {
                        if (res.rpStatus === 10000) {
                            this.searchForm={
                                pageNo:1,
                                pageSize:15,
                                car_name:'',
                                car_no:'',
                                cp_id:0
                            }
                            this.$store.dispatch('getCarDataList',this.searchForm)
                            this.dialogVisible = false
                        } else { this.$message.error(res.rpMsg) }
                    })
                }
            })
        },
        BlackListOpt(command, row){
            this.$confirm('确定设置车辆“' + row.car_no + '”'+(command==='1'?'黑名单':'白名单')+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var form={
                    opt_type:command,
                    car_id:row.car_id,
                    plateno:row.car_no
                }
                post('car/blackopt',form).then(res => {
                    if (res.rpStatus === 10000) {
                        this.searchForm={
                            pageNo:1,
                            pageSize:15,
                            car_name:'',
                            car_no:'',
                            cp_id:0
                        }
                        this.$store.dispatch('getCarDataList',this.searchForm)
                    } else { this.$message.error(res.rpMsg) }
                })
            }).catch(e => e)
        }
    }
}
</script>

<style>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.search{
    display: flex;
    flex-direction: row;
    justify-self: start;
    align-items: center;
}
.item{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
}
.item label{
  margin-right: 8px;
  width: 80px;
  text-align: right;
}
.el-table{
    margin-top: 10px;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
</style>